import React, { useMemo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import RichText from '~components/RichText';
import clsx from '~src/styles/clsx';

const MAX_ANIMATION_SCROLL = 700;
const END_ANIMATION_IMAGE_SHOWN_SCROLL_THRESHOLD = 0.5; // shown at 50%

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: props => props.background_color || '#FFFFFF',
    maxHeight: '92vh',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 57px)',
      maxHeight: 'calc(100vh - 57px)'
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      maxHeight: 'unset'
    }
  },
  hero: {
    width: '100%',
    maxHeight: '92vh',
    objectFit: 'cover',
    objectPosition: props => props.video_focus || 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 57px)',
      maxHeight: 'calc(100vh - 57px)'
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      maxHeight: 'unset'
    }
  },
  titleContainer: {
    position: 'absolute',
    bottom: 48,
    left: 72,
    width: '90%',
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      bottom: 36,
      left: 56
    },
    [theme.breakpoints.down('sm')]: {
      bottom: 48,
      left: 44
    },
    [theme.breakpoints.down('xs')]: {
      top: 20,
      left: 0,
      width: '100%',
      padding: '0px 16px'
    }
  },
  heroTitle: props => ({
    textAlign: 'left',
    maxWidth: '1082px',
    '& > h1': {
      marginTop: 0,
      marginBottom: 16,
      color: props.title_color || '#FFFFFF',
      fontSize: 64,
      fontFamily: 'Inter',
      fontWeight: '700',
      lineHeight: '130%',
      [theme.breakpoints.down('md')]: {
        fontSize: '44px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '36px',
        margin: '0px auto 12px'
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '310px',
        fontSize: '32px',
        marginBottom: 9
      }
    },
    '& > h4': {
      marginTop: 0,
      marginBottom: 0,
      color: props.title_color || '#FFFFFF',
      fontSize: 42,
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '130%',
      maxWidth: '950px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        margin: '0px auto'
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '320px',
        fontSize: '18px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      margin: 'auto',
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    }
  }),
  descriptionRoot: props => ({
    backgroundColor: props.background_color || '#FFFFFF',
    background: `linear-gradient(transparent 0px, ${
      props.background_color || '#FFFFFF'
    } 10px) !important`,
    marginTop: '-15px',
    position: 'relative',
    zIndex: 10
  }),
  descriptionText: props => ({
    padding: '88px 40px 73px',
    maxWidth: '833px',
    margin: 'auto',
    '& > *': {
      marginTop: 0,
      marginBottom: 16,
      color: props.mobile_description_color || '#000000',
      fontSize: 32,
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '130%',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '28px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px'
      }
    }
  }),
  animationDescriptionRoot: props => ({
    backgroundColor: props.animation_end_background_color || '#FFFFFF',
    position: 'relative',
    zIndex: 10
  }),
  animationDescriptionText: props => ({
    padding: '0px 0px 36px',
    maxWidth: '833px',
    margin: 'auto',
    '& > *': {
      marginTop: 0,
      marginBottom: 16,
      color: props.animation_end_description_color || '#000000',
      fontSize: 32,
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '130%',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '28px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px'
      }
    }
  }),
  animOuterRoot: props => ({
    position: 'relative',
    backgroundColor: props.isAnimationStarted
      ? props.animation_end_background_color
      : props.background_color
  }),
  animContentArea: {
    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
      top: '68px',
      left: 0,
      width: '100%',
      height: 'auto',
      zIndex: 50
    }
  },
  animRoot: {
    outline: '20px solid black',
    borderRadius: '20px',
    overflow: 'hidden'
  },
  animScrollableArea: {
    height: `calc(75vh + ${MAX_ANIMATION_SCROLL}px)`
  },
  animMediaArea: {
    position: 'relative',
    width: '100%'
  },
  animLaptopStand: {
    height: '40px',
    position: 'absolute',
    bottom: -30,
    backgroundColor: 'black',
    left: '50%',
    transform: 'translateX(-50%)',
    borderBottomLeftRadius: 60,
    borderBottomRightRadius: 60,
    zIndex: 65,
    boxShadow: '0px 18px 27px rgba(0, 0, 0, 0.10)'
  },
  animEndImage: {
    position: 'absolute',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 60,
    opacity: 0
  },
  animEndBgUnderlay: props => ({
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    zIndex: 55,
    backgroundColor: props.animation_end_background_color,
    opacity: 0
  }),
  animOpacityToOne: {
    animation: '$animHeroFadeIn 1s forwards'
  },
  animOpacityToZero: {
    animation: '$animHeroFadeOut 1s forwards'
  },
  '@keyframes animHeroFadeIn': {
    '100%': {
      opacity: 1
    }
  },
  '@keyframes animHeroFadeOut': {
    '100%': {
      opacity: 0
    }
  }
}));

const AnimationHero = props => {
  const {
    hero_rich_title,
    rich_text_description,
    hero_video,
    hero_video_mp4,
    hero_mobile_video,
    hero_mobile_video_mp4,
    video_focus,
    background_color,
    title_color,
    mobile_description_color,
    disable_animation,
    animation_end_image,
    animation_end_background_color,
    animation_end_description_color,
    animation_description
  } = props;

  const theme = useTheme();
  // animation related
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);
  const [screenRatio, setScreenRatio] = useState(1.77); // default 16:9 ratio
  const animContentAreaRef = useRef(null);
  const animLaptopStandRef = useRef(null);
  const [showAnimationImage, setShowAnimationImage] = useState(false);
  const isAboveLgScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  /**
   * Animation is active only when screen is above lg and it's not disabled in prismic
   * Animation is disabled when building.
   * So that until page load, animation is not shown and users can scroll through the page.
   */
  const isScrollableAnimationActive =
    !disable_animation && isAboveLgScreen && typeof window !== 'undefined';

  // general styles
  const classes = useStyles({
    video_focus,
    title_color,
    mobile_description_color,
    background_color,
    animation_end_background_color,
    animation_end_description_color,
    isAnimationFinished,
    isAnimationStarted
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  const videoWebmUrl = useMemo(
    () => (isMobile ? hero_mobile_video : hero_video),
    [isMobile, hero_mobile_video, hero_video]
  );
  const videoMp4Url = useMemo(
    () => (isMobile ? hero_mobile_video_mp4 : hero_video_mp4),
    [isMobile, hero_mobile_video_mp4, hero_video_mp4]
  );

  // calculate screen ratio
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScreenRatio(window.innerWidth / window.innerHeight);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenRatio(window.innerWidth / window.innerHeight);
    };

    if (isScrollableAnimationActive && typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (isScrollableAnimationActive && typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [isScrollableAnimationActive]);

  // calculate scroll position
  useEffect(() => {
    let ticking = false;
    let animStarted = false;
    let animFinished = false;
    let showAnimImage = false;
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (window.scrollY <= MAX_ANIMATION_SCROLL) {
            const { scrollY } = window;
            if (animFinished) {
              setIsAnimationFinished(false);
              animFinished = false;
            }
            // stop animation at top
            if (scrollY <= 1 && animStarted) {
              setIsAnimationStarted(false);
              animStarted = false;
            }
            // start animation
            if (scrollY > 1 && !animStarted) {
              setIsAnimationStarted(true);
              animStarted = true;
            }

            const scrollPercentage = scrollY / MAX_ANIMATION_SCROLL;

            // animation image show/hide
            if (!showAnimImage && scrollPercentage >= END_ANIMATION_IMAGE_SHOWN_SCROLL_THRESHOLD) {
              setShowAnimationImage(true);
              showAnimImage = true;
            } else if (
              showAnimImage &&
              scrollPercentage < END_ANIMATION_IMAGE_SHOWN_SCROLL_THRESHOLD
            ) {
              setShowAnimationImage(false);
              showAnimImage = false;
            }

            // update the dom styles
            if (animContentAreaRef?.current) {
              animContentAreaRef.current.style.width = `calc(100vw - 50vw * ${scrollPercentage})`;
              animContentAreaRef.current.style.marginTop = `calc(72px * ${scrollPercentage})`;
            }
            if (animLaptopStandRef?.current) {
              animLaptopStandRef.current.style.width = `calc(100vw - 50vw * ${scrollPercentage} + 180px)`;
            }
          } else {
            // finish animation
            if (!animFinished) {
              setIsAnimationFinished(true);
              animFinished = true;

              // update final styles
              if (animContentAreaRef?.current) {
                animContentAreaRef.current.style.width = `50vw`;
                animContentAreaRef.current.style.marginTop = `72px`;
              }
              if (animLaptopStandRef?.current) {
                animLaptopStandRef.current.style.width = `calc(50vw + 180px)`;
              }
            }
            // show image
            if (!showAnimImage) {
              setShowAnimationImage(true);
              showAnimImage = true;
            }
          }
          ticking = false;
        });
      }

      ticking = true;
    };

    if (isScrollableAnimationActive && typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (isScrollableAnimationActive && typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isScrollableAnimationActive]);

  const animationStyles = useMemo(() => {
    if (!isScrollableAnimationActive) {
      return {};
    }
    if (isAnimationFinished) {
      return {
        position: 'absolute',
        top: `${MAX_ANIMATION_SCROLL}px`,
        left: '50%',
        transform: 'translateX(-50%)'
      };
    }
    if (isAnimationStarted) {
      return {
        left: '50%',
        transform: 'translateX(-50%)'
      };
    }
    return {};
  }, [isScrollableAnimationActive, isAnimationStarted, isAnimationFinished]);

  const animDescriptionStyles = useMemo(() => {
    /**
     * Below linear equation is defined based on trial and error method for different screen ratios
     * and taking the best fitting linear function for the margin top value.
     * marginTop = a x screenRatio + b
     * a = 220, b = -395
     * above coefficient values are determined based on the linear function drawn over the trial and error results
     */
    const marginTop = Math.round(220 * screenRatio - 395);
    return { marginTop };
  }, [screenRatio]);

  /**
   * Note that for scroll animation related elements, additional styles attribute has been used.
   * Reason to use styles is that, passing props to classes doesn't work as intended with scrolling.
   */

  return (
    <section className={classes.animOuterRoot}>
      <div
        ref={animContentAreaRef}
        className={clsx(isScrollableAnimationActive && classes.animContentArea)}
        style={animationStyles}
      >
        <section className={classes.root}>
          <div className={clsx(classes.animMediaArea, isAnimationStarted && classes.animRoot)}>
            <video
              key={videoWebmUrl?.url || videoMp4Url?.url}
              className={classes.hero}
              autoPlay
              muted
              loop
              playsInline
              controls={false}
              disablePictureInPicture
            >
              {videoWebmUrl?.url && <source src={videoWebmUrl.url} type="video/webm" />}
              {videoMp4Url?.url && <source src={videoMp4Url.url} type="video/mp4" />}
              Your browser does not support the video tag.
            </video>
            {isAnimationStarted && animation_end_image?.url && (
              <>
                <img
                  src={animation_end_image.url}
                  alt={animation_end_image.alt}
                  className={clsx(
                    classes.animEndImage,
                    showAnimationImage ? classes.animOpacityToOne : classes.animOpacityToZero
                  )}
                />
                <div
                  className={clsx(
                    classes.animEndBgUnderlay,
                    showAnimationImage ? classes.animOpacityToOne : classes.animOpacityToZero
                  )}
                />
              </>
            )}
          </div>
          {!isAnimationStarted && (
            <div className={classes.titleContainer}>
              <RichText
                html={hero_rich_title?.html}
                externalClassName={classes.heroTitle}
                verticalSpacing={0}
              />
            </div>
          )}
          {isAnimationStarted && (
            <div ref={animLaptopStandRef} className={classes.animLaptopStand} />
          )}
        </section>
      </div>

      {isScrollableAnimationActive && <div className={classes.animScrollableArea} />}
      {!isScrollableAnimationActive && rich_text_description?.html && (
        <section className={classes.descriptionRoot}>
          <RichText
            html={rich_text_description?.html}
            externalClassName={classes.descriptionText}
            verticalSpacing={0}
          />
        </section>
      )}
      {isScrollableAnimationActive && animation_description?.html && (
        <section className={classes.animationDescriptionRoot} style={animDescriptionStyles}>
          <RichText
            html={animation_description?.html}
            externalClassName={classes.animationDescriptionText}
            verticalSpacing={0}
          />
        </section>
      )}
    </section>
  );
};

AnimationHero.propTypes = {
  hero_rich_title: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string
  }),
  rich_text_description: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string
  }),
  hero_video: PropTypes.shape({
    url: PropTypes.string
  }).isRequired,
  hero_video_mp4: PropTypes.shape({
    url: PropTypes.string
  }),
  hero_mobile_video: PropTypes.shape({
    url: PropTypes.string
  }),
  hero_mobile_video_mp4: PropTypes.shape({
    url: PropTypes.string
  }),
  video_focus: PropTypes.oneOf(['center', 'left', 'right', 'top', 'bottom']),
  background_color: PropTypes.string,
  title_color: PropTypes.string,
  mobile_description_color: PropTypes.string,
  disable_animation: PropTypes.bool,
  animation_end_image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }),
  animation_end_background_color: PropTypes.string,
  animation_end_description_color: PropTypes.string,
  animation_description: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string
  })
};

AnimationHero.defaultProps = {
  hero_rich_title: null,
  rich_text_description: null,
  hero_video_mp4: null,
  hero_mobile_video: null,
  hero_mobile_video_mp4: null,
  disable_animation: false,
  animation_end_image: null,
  video_focus: 'center',
  background_color: '#FFFFFF',
  title_color: '#FFFFFF',
  mobile_description_color: '#000000',
  animation_end_background_color: '#FFFFFF',
  animation_end_description_color: '#000000',
  animation_description: null
};

export default AnimationHero;
